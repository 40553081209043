<template>
  <div class="dd-header">
    <nuxt-link
      to="/"
      @click.native="handleHomePage"
    >
      <h1 class="dd-logo"></h1>
    </nuxt-link>

    <div class="nav-bar">
      <div class="nav-item">
        <nuxt-link
          exact
          :to="localePath('/')"
        >{{$t('home')}}</nuxt-link>
      </div>
      <div class="nav-item">
        <nuxt-link :to="localePath('/artists')">{{$t('artists')}}</nuxt-link>
      </div>
      <div class="nav-item">
        <nuxt-link :to="localePath('/news')">{{$t('news')}}</nuxt-link>
      </div>
      <div class="nav-item">
        <nuxt-link :to="localePath('/about')">{{$t('about')}}</nuxt-link>
      </div>

      <div class="lang">
        <nuxt-link
          exact
          :to="switchLocalePath('ja')"
        >{{$t('ja')}}</nuxt-link>
        <span class="vline">|</span>
        <nuxt-link
          exact
          :to="switchLocalePath('zh')"
        >{{$t('zh')}}</nuxt-link>
        <span class="vline">|</span>
        <nuxt-link
          exact
          :to="switchLocalePath('en')"
        >{{$t('en')}}</nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    handleHomePage () {
      if (this.$route.path === '/') {
        this.$router.go(0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dd-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0 auto;
  width: 1280px;
  height: 70px;
  background: #fff;

  .dd-logo {
    display: block;
    width: 108px;
    height: 33px;
    background: url('~assets/img/common/logo-default.png') center/100% no-repeat;
  }

  .nav-bar {
    display: flex;
    align-items: center;
    height: 20px;

    .nav-item {
      display: flex;
      margin-left: 48px;
      cursor: pointer;

      a {
        color: #000000;
        font-weight: 200;
        font-size: 16px;
        line-height: 20px;

        &.nuxt-link-exact-active,
        &.nuxt-link-active {
          font-weight: 500;
          opacity: 1;
        }
      }
    }

    .lang {
      display: flex;
      align-items: center;
      margin-left: 80px;

      a {
        color: #000000;
        font-weight: 200;
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        line-height: 20px;

        &.nuxt-link-exact-active,
        &.nuxt-link-active {
          font-weight: bold;
          // font-family: PingFangSC-Semibold, PingFang SC;
          opacity: 1;
        }
      }

      .vline {
        margin: 0 10px;
      }
    }
  }
}
</style>
