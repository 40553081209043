module.exports = {
  ja: '日本語',
  zh: '中国語',
  en: '英語',
  home: 'ホーム',
  artists: 'アーティスト',
  news: '展示会情報',
  about: '私たちについて',
  preNews: '過去の展示会情報',
  readMore: 'もっと詳しく',
  tokyo: '東京',
  beijing: '北京',
  openTime: '営業時間',
  contact: 'お問合せ',
  wechat: '微信',
  xiaohongshu: '小紅書',
  instagram: 'インスタグラム',
  tokyoAddress: '140-0002東京都品川区東品川１丁目３２−８ Terrada Art Complex Ⅱ 2F Contemporary Tokyo',
  beijingAddress: '当代東京画廊、中国北京市順義区金航東路3号院D7楼3F（北京天竺保税区）',
  details: '詳細',
  time: '時間',
  address: '場所',
  noMore: 'これ以上ない',
  loading: 'ロード中...'
}
