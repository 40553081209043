// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/common/logo-default.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/common/heng.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dd-header[data-v-15b09ef9]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;padding:5.867vw 5.867vw 0;width:100vw;height:22.4vw;background:#fff}.dd-header .dd-logo[data-v-15b09ef9]{display:inline-block;overflow:hidden;width:26.933vw;height:8.267vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50%/100% no-repeat}.dd-header .menu[data-v-15b09ef9]{display:inline-block;width:6.4vw;height:5.867vw;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 50%/contain no-repeat;cursor:pointer}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
