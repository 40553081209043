<template>
  <div
    class="nav"
    @touchmove.prevent
  >
    <svg
      class="icon close"
      :class="{'ani': activeAnimation}"
      aria-hidden="true"
      @click="() => handleClose(true)"
    >
      <use xlink:href="#icon-guanbi"></use>
    </svg>

    <div class="nav-main">
      <div class="lang">
        <nuxt-link
          exact
          :to="switchLocalePath('ja')"
        ><span @click="() => handleClose()">{{$t('ja')}}</span></nuxt-link>
        <span class="vline">|</span>
        <nuxt-link
          exact
          :to="switchLocalePath('zh')"
        ><span @click="() => handleClose()">{{$t('zh')}}</span></nuxt-link>
        <span class="vline">|</span>
        <nuxt-link
          exact
          :to="switchLocalePath('en')"
        ><span @click="() => handleClose()">{{$t('en')}}</span></nuxt-link>
      </div>

      <nuxt-link
        exact
        :to="localePath('/mobile')"
      >
        <div
          class="nav-main-item"
          @click.stop="() => handleClose()"
        >{{$t('home')}}</div>
      </nuxt-link>

      <nuxt-link :to="localePath('/mobile/artists')">
        <div
          class="nav-main-item"
          @click.stop="() => handleClose()"
        >{{$t('artists')}}</div>
      </nuxt-link>

      <nuxt-link :to="localePath('/mobile/news')">
        <div
          class="nav-main-item"
          @click.stop="() => handleClose()"
        >{{$t('news')}}</div>
      </nuxt-link>

      <nuxt-link :to="localePath('/mobile/about')">
        <div
          class="nav-main-item"
          @click.stop="() => handleClose()"
        >{{$t('about')}}</div>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeAnimation: false
    }
  },
  props: {
    showNavBar: Boolean
  },
  methods: {
    handleClose (animation) {
      if (animation) {
        this.activeAnimation = animation

        setTimeout(() => {
          this.$emit('update:showNavBar', false)
          this.activeAnimation = false
        }, 500)
      } else {
        this.activeAnimation = false
        this.$emit('update:showNavBar', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  overflow: auto;
  box-sizing: border-box;
  padding-top: 190px;
  width: 100vw;
  height: 100vh;
  background: #fff;

  .close {
    position: absolute;
    top: 26px;
    right: 22px;
    width: 22px;
    height: 22px;
  }

  .ani {
    animation: rotate90 0.3s forwards;
  }

  @keyframes rotate90 {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(90deg);
    }
  }

  &-main {
    flex: 1;

    .lang {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;
      color: #000000;
      font-size: 14px;

      .vline {
        margin: 0 10px;
        margin-top: -2px;
      }

      a {
        &.nuxt-link-exact-active,
        &.nuxt-link-active {
          font-weight: bold;
          opacity: 1;
        }
      }
    }

    &-item {
      box-sizing: border-box;
      margin-bottom: 5px;
      color: #000;
      text-align: center;
      font-size: 18px;
      line-height: 43px;
    }

    ::v-deep .nuxt-link-exact-active,
    ::v-deep .nuxt-link-active {
      .nav-main-item {
        font-weight: bold;
      }
    }
  }
}
</style>
