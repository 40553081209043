<template>
  <div class="footer">
    <div class="footer-inner">
      <div class="left">
        <div class="footer-item">
          <p class="title">{{$t('tokyo')}}</p>
          <p>{{$t('tokyoAddress')}}</p>
        </div>

        <div class="footer-item">
          <p class="title">{{$t('beijing')}}</p>
          <p v-html="$t('beijingAddress')"></p>
        </div>

        <div class="footer-item">
          <p class="title">{{$t('openTime')}}</p>
          <div>
            <p>12:00-18:00 {{$t('tokyo')}}</p>
            <p>11:00-18:00 {{$t('beijing')}}</p>
          </div>
        </div>

        <div class="footer-item">
          <p class="title">{{$t('contact')}}</p>
          <p>official@ctokyoart.com</p>
        </div>
      </div>

      <div class="right">
        <img
          @click="() => handleShowPreviewQrcode(0)"
          style="width: 29px; height: 23px;"
          src="~assets/img/common/wechat.jpg"
        >

        <img
          @click="() => handleShowPreviewQrcode(1)"
          src="~assets/img/common/xhs.jpg"
        >

        <img
          @click="() => handleShowPreviewQrcode(2)"
          src="~assets/img/common/ins.jpg"
        >
      </div>
    </div>

    <p class="copyright">COPYRIGHT ©2010-2020当代东京, LTD. ALL RIGHTS RESERVED. </p>

    <drawer :show.sync="showDrawer">
      <template #content>
        <div
          class="qrcode-detail"
          v-if="currQrcodeIndex == 0"
        >
          <img
            class="icon icon-wx"
            src="~assets/img/common/wechat.jpg"
          >
          <img
            class="qrcode"
            src="~assets/img/common/wechat-qrcode.jpg"
          >
          <p>{{$t('wechat')}}</p>
        </div>
        <div
          class="qrcode-detail"
          v-else-if="currQrcodeIndex ==1"
        >
          <img
            class="icon"
            src="~assets/img/common/xhs.jpg"
          >
          <img
            class="qrcode"
            src="~assets/img/common/xhs-qrcode.jpg"
          >
          <p>{{$t('xiaohongshu')}}</p>

        </div>
        <div
          class="qrcode-detail"
          v-else-if="currQrcodeIndex ==2"
        >
          <img
            class="icon"
            src="~assets/img/common/ins.jpg"
          >
          <img
            class="qrcode"
            src="~assets/img/common/ins-qrcode.jpg"
          >
          <p>{{$t('instagram')}}</p>
        </div>
      </template>
    </drawer>
  </div>
</template>

<script>
import Drawer from '@mobile/common/Drawer'

export default {
  data () {
    return {
      showDrawer: false,
      currQrcodeIndex: 0
    }
  },

  components: {
    Drawer
  },

  methods: {
    handleShowPreviewQrcode (index) {
      this.showDrawer = true
      this.currQrcodeIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  box-sizing: border-box;
  margin: 0 22px;
  padding-top: 20px;
  border-top: 1px solid #e5e5e5;
  color: #5e5e5e;
  word-break: break-all;

  .footer-inner {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .left {
    width: 244px;
  }

  .footer-item {
    margin-bottom: 15px;
    word-break: break-word;

    p {
      font-size: 12px;
      line-height: 17px;
    }

    .title {
      margin-bottom: 9px;
      color: #000000;
      font-weight: bold;
      font-size: 13px;
      line-height: 1;
    }
  }

  .right {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      margin-bottom: 15px;
      width: 26px;
      height: 25px;
    }
  }

  .copyright {
    margin: 20px 0 29px;
    width: 200%;
    color: #5e5e5e;
    font-size: 16px;
    transform: scale(0.5) translateX(-50%);
  }

  .qrcode-detail {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: -50px;
    height: 100vh;
    // padding-top: 118px;

    .icon {
      width: 26px;
      height: 25px;
    }

    .icon-wx {
      width: 29px;
      height: 23px;
    }

    .qrcode {
      margin: 28px 0 15px;
      width: 150px;
      height: 154px;

      object-fit: contain;
    }

    p {
      color: #000000;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>
