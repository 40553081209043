<template>
  <div
    class="dd-header"
    @touchmove.prevent
  >
    <nuxt-link :to="localePath('/mobile')">
      <h1 class="dd-logo"></h1>
    </nuxt-link>

    <div v-show="!showNavBar">
      <span
        class="menu"
        @click="toggleMenu"
      ></span>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showNavBar: Boolean
  },
  methods: {
    toggleMenu () {
      this.$emit('update:showNavBar', !this.showNavBar)
    }
  }
}
</script>

<style lang="scss" scoped>
.dd-header {
  display: flex;
  justify-content: space-between;
  padding: 22px 22px 0;
  width: 100vw;
  height: 84px;
  background: #fff;

  .dd-logo {
    display: inline-block;
    overflow: hidden;
    width: 101px;
    height: 31px;
    background: url('~assets/img/common/logo-default.png') center/100% no-repeat;
  }

  .menu {
    display: inline-block;
    width: 24px;
    height: 22px;
    background: url('~assets/img/common/heng.jpg') center/contain no-repeat;
    cursor: pointer;
  }
}
</style>
