import Api from '@/api/news'

export const state = () => ({
  list: [],
  detail: {}
})

export const mutations = {
  SET_LIST (state, data) {
    state.list = data.list
  },

  SET_DETAIL (state, data) {
    state.detail = data
  }
}

export const actions = {
  async getList ({ commit, state }, { app }) {
    const res = await Api.getList({
      current: 1,
      page_num: 100,
      lang: app.i18n.getLocaleCookie()
    })

    if (res.ok) {
      const { records } = res.data

      // const list = records.map(item => {
      //   item.newsList = item.newsList.concat(item.newsList).concat(item.newsList)
      //   return item
      // })
      // const list1 = [{ ...list[0], year: 2021 }]
      // const list2 = [{ ...list[0], year: 2020 }]

      commit('SET_LIST', {
        list: records
        // list: [...list, ...list1, ...list2]
      })
    }
  },

  async getDetail ({ commit }, { id, app }) {
    const res = await Api.getDetail({
      id,
      lang: app.i18n.getLocaleCookie()
    })

    if (res.ok) {
      const { detail: { content, artists }, previous, next } = res.data

      // 实现图片懒加载
      if (content) {
        res.data.detail.content = content.replace(/<img(.*?)(src)="(.*?)"(.*?)>/gi, '<img$1data-src="$3"$4src="$3">')
      }

      if (artists && artists.length) {
        res.data.detail.artists = artists.sort((a, b) => b.sortWeight - a.sortWeight)
      }

      if (previous && previous.id) {
        res.data.detail.prevId = previous.id
      }

      if (next && next.id) {
        res.data.detail.nextId = next.id
      }

      commit('SET_DETAIL', res.data.detail)
    }
  }
}
