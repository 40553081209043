<template>
  <div class="pc-container">
    <nuxt class="pc-content" />
  </div>
</template>

<script>

export default {
  data () {
    return {
      headNoSpace: true
    }
  }
}
</script>

<style lang="scss" scoped>
.pc-container {
  flex-direction: column;
  justify-content: space-between;
  min-width: 1280px;
  min-height: 100vh;
}

.pc-content {
  min-height: calc(100vh - 68px);
}

.__nuxt-error-page {
  position: relative;
}
</style>
