
module.exports = {
  ja: 'JAPANESE',
  zh: 'CHINESE',
  en: 'ENGLISH',
  home: 'HOME',
  artists: 'ARTISTS',
  news: 'EXHIBITIONS & NEWS',
  about: 'ABOUT',
  preNews: 'PAST EXHIBITIONS & NEWS',
  readMore: 'READ MORE',
  tokyo: 'TOKYO',
  beijing: 'BEIJING',
  openTime: 'OPEN TIME',
  contact: 'CONTACT',
  wechat: 'WECHAT',
  xiaohongshu: 'XIAOHONGSHU',
  instagram: 'INSTAGRAM',
  tokyoAddress: '〒140-0002 2F 1Chome-32-8, Higashishinagawa, Shinagawa, Tokyo, Japan, Contemporary Tokyo TERRADA ART COMPLEX II',
  beijingAddress: 'Contemporary Tokyo, 3F, Building D7, Yard No.3, East Jinhang Road, Shunyi District, Beijing<br />(Tianzhu Comprehensive Bonded Zone)',
  details: 'Details',
  time: 'Time',
  address: 'Address',
  noMore: 'NO MORE',
  loading: 'Loading...'
}
