<template>
  <div
    class="dd-container"
    :class="locale == 'ja' ? 'ja-footer' : ''"
  >
    <div class="dd-footer">
      <div class="dd-footer-left">
        <div class="dd-footer-left-child">
          <p class="title">{{$t('tokyo')}}</p>
          <p>{{$t('tokyoAddress')}}</p>
        </div>

        <div class="dd-footer-left-child">
          <p class="title">{{$t('beijing')}}</p>
          <p v-html="$t('beijingAddress').replace('<br />', '')"></p>
        </div>

        <div class="dd-footer-left-child">
          <p class="title">{{$t('openTime')}}</p>
          <div>
            <p>12:00-18:00 {{$t('tokyo')}}</p>
            <p>11:00-18:00 {{$t('beijing')}}</p>
          </div>

          <p
            class="title"
            style="margin-top: 21px;"
          >{{$t('contact')}}</p>
          <p>official@ctokyoart.com</p>
        </div>
      </div>

      <div class="dd-footer-right">
        <div class="dd-footer-right-child">
          <div class="dd-footer-right-child-top">
            <img
              style="width: 29px; height: 23px;"
              src="~assets/img/common/wechat.jpg"
            >
            <p>{{$t('wechat')}}</p>
          </div>

          <div class="qrcode">
            <img src="~assets/img/common/wechat-qrcode.jpg">
          </div>
        </div>

        <div class="dd-footer-right-child dd-footer-right-middle">
          <div class="dd-footer-right-child-top">
            <img src="~assets/img/common/xhs.jpg">
            <p>{{$t('xiaohongshu')}}</p>
          </div>

          <div class="qrcode">
            <img src="~assets/img/common/xhs-qrcode.jpg">
          </div>
        </div>

        <div class="dd-footer-right-child">
          <div class="dd-footer-right-child-top">
            <img src="~assets/img/common/ins.jpg">
            <p>{{$t('instagram')}}</p>
          </div>

          <div class="qrcode">
            <img src="~assets/img/common/ins-qrcode.jpg">
          </div>
        </div>

      </div>
    </div>

    <p class="copyright">COPYRIGHT ©2010-2020当代东京, LTD. ALL RIGHTS RESERVED.</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      locale: ''
    }
  },
  watch: {
    '$store.$i18n.locale': {
      immediate: true,
      deep: true,
      handler (newValue) {
        this.locale = newValue
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dd-footer {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 43px;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  color: #5e5e5e;
  font-size: 12px;
  line-height: 18px;

  .title {
    color: #000000;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  .dd-footer-left,
  .dd-footer-right {
    display: flex;
  }

  .dd-footer-left-child {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    width: 220px;

    .title {
      margin-bottom: 8px;
    }
  }

  .dd-footer-right-child {
    display: flex;
    align-items: center;
    flex-direction: column;

    &.dd-footer-right-middle {
      margin: 0 27px 0 35px;
    }

    .dd-footer-right-child-top {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;

      img {
        width: 26px;
        height: 25px;
      }

      p {
        margin-left: 4px;
        color: #000000;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
    }

    .qrcode {
      width: 77px;
      height: 79px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.dd-container.ja-footer {
  .dd-footer-right-middle {
    margin-left: 58px;
  }
}

.copyright {
  margin: 40px 0 47px;
  color: #5e5e5e;
  font-weight: 400;
  font-size: 12px;
}
</style>
