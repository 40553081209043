<template>
  <transition name="fade">
    <div
      v-if="show"
      class="drawer"
      @touchmove.prevent
    >
      <svg
        class="icon close"
        :class="{'ani': activeAnimation}"
        aria-hidden="true"
        @click="() => handleClose(true)"
      >
        <use xlink:href="#icon-guanbi"></use>
      </svg>

      <div :class="{'inner': addPadding}">
        <slot name="content"></slot>
      </div>
    </div>
  </transition>

</template>

<script>
export default {
  data () {
    return {
      activeAnimation: false
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    addPadding: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleClose (animation) {
      if (animation) {
        this.activeAnimation = animation

        setTimeout(() => {
          this.$emit('update:show', false)
          this.activeAnimation = false
        }, 500)
      } else {
        this.activeAnimation = false
        this.$emit('update:show', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: #fff;

  .inner {
    overflow: auto;
    padding-top: calc(100vh - 570px);
    height: 100vh;
  }

  .close {
    position: absolute;
    top: 26px;
    right: 22px;
    z-index: 10000;
    width: 20px;
    height: 20px;
    font-size: 22px;
  }

  .ani {
    animation: rotate90 0.3s forwards;
  }

  @keyframes rotate90 {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(90deg);
    }
  }
}
</style>
