<template>
  <div class="m-container">
    <!-- <Sticky
      :zIndex="500"
      :defaultPosition="headerDefalutFixed? 'fixed' : ''"
    >
      <PageHeader
        :showNavBar.sync="showNavBar"
      />
    </Sticky> -->
    <PageHeader :showNavBar.sync="showNavBar" />

    <transition name="fade-in">
      <NavBar
        :showNavBar.sync="showNavBar"
        v-if="showNavBar"
      />
    </transition>

    <nuxt class="m-content" />

    <PageFooter />
  </div>
</template>

<script>
import PageHeader from '@mobile/common/PageHeader'
import PageFooter from '@mobile/common/PageFooter'

import NavBar from '@mobile/common/NavBar'
// import Sticky from '@common/sticky'

export default {
  data () {
    return {
      headerDefalutFixed: false,
      showNavBar: false
    }
  },
  watch: {
    $route: {
      handler: function () {
        // 需要page-header默认fixed的page path
        const fixedHeaderPath = ['/mobile']
        const routePath = this.$route.path
        const headerDefalutFixed = fixedHeaderPath.includes(routePath)
        this.$nextTick(() => {
          this.headerDefalutFixed = headerDefalutFixed
        })
      },
      immediate: true
    }
  },
  components: {
    PageHeader,
    PageFooter,
    NavBar
    // Sticky
  }
}
</script>

<style lang="scss" scoped>
.m-content {
  min-height: calc(100vh - 84px - 363px);
}
</style>
