import Base from '../Base'
class Home extends Base {
  constructor () {
    super()
    this.baseURL = process.env.API_BASE_URL
    this.getHomepageUrl = '/api/home/info'
  }

  // 获取官网首页banner及展讯
  getHomepage (params) {
    return this.sendGet(this.getHomepageUrl, params)
  }
}
export default new Home()
