<template>
  <Layout />
</template>

<script>
import Layout from '@mobile/common/Layout'
export default {
  components: {
    Layout
  }
}
</script>
