import homeApi from '~/api/home'

export const state = () => ({
  bannerList: [],
  newsList: []
})

export const mutations = {
  SET_HOME (state, data) {
    state.bannerList = data.bannerList
    state.newsList = data.newsList
  }
}

export const actions = {
  async getHomeData ({ commit }, app) {
    const res = await homeApi.getHomepage({
      lang: app.i18n.getLocaleCookie()
    })

    if (res.ok) {
      let { bannerList, newsList } = res.data
      newsList = newsList.map(news => {
        news.content = news.content.replace(/<\/?.+?\/?>/g, '')
        return news
      })

      commit('SET_HOME', {
        bannerList,
        newsList: newsList.slice(0, 3)
      })
    }
  }
}
