module.exports = {
  ja: '日文',
  zh: '中文',
  en: '英文',
  home: '首页',
  artists: '艺术家',
  news: '展览及展讯',
  about: '关于',
  preNews: '过往展览及展讯',
  readMore: '更多',
  tokyo: '东京',
  beijing: '北京',
  openTime: '开放时间',
  contact: '联系方式',
  wechat: '微信',
  xiaohongshu: '小红书',
  instagram: 'instagram',
  tokyoAddress: '140-0002東京都品川区東品川１丁目３２−８ Terrada Art Complex Ⅱ 2F Contemporary Tokyo',
  beijingAddress: '当代东京画廊，北京市顺义区金航东路3号院D7楼3层（北京天竺保税区）',
  details: '详情',
  time: '时间',
  address: '地点',
  noMore: '没有更多了',
  loading: '加载中...'
}
