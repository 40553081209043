// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/common/logo-default.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dd-header[data-v-9b44f3ec]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;-webkit-box-sizing:border-box;box-sizing:border-box;margin:0 auto;width:1280px;height:70px;background:#fff}.dd-header .dd-logo[data-v-9b44f3ec]{display:block;width:108px;height:33px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50%/100% no-repeat}.dd-header .nav-bar[data-v-9b44f3ec]{-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:20px}.dd-header .nav-bar[data-v-9b44f3ec],.dd-header .nav-bar .nav-item[data-v-9b44f3ec]{display:-webkit-box;display:-ms-flexbox;display:flex}.dd-header .nav-bar .nav-item[data-v-9b44f3ec]{margin-left:48px;cursor:pointer}.dd-header .nav-bar .nav-item a[data-v-9b44f3ec]{color:#000;font-weight:200;font-size:16px;line-height:20px}.dd-header .nav-bar .nav-item a.nuxt-link-active[data-v-9b44f3ec],.dd-header .nav-bar .nav-item a.nuxt-link-exact-active[data-v-9b44f3ec]{font-weight:500;opacity:1}.dd-header .nav-bar .lang[data-v-9b44f3ec]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-left:80px}.dd-header .nav-bar .lang a[data-v-9b44f3ec]{color:#000;font-weight:200;font-size:16px;line-height:20px}.dd-header .nav-bar .lang a.nuxt-link-active[data-v-9b44f3ec],.dd-header .nav-bar .lang a.nuxt-link-exact-active[data-v-9b44f3ec]{font-weight:700;opacity:1}.dd-header .nav-bar .lang .vline[data-v-9b44f3ec]{margin:0 10px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
